import axios from '@/axios'
import helpers from '@/utils/helpers'

export default {
  fetch (params = {}) {
    return axios.get('members', {
      params: helpers.removePropertyIsEmpty(params),
    })
  },
  create (data) {
    return axios.post('members', data)
  },
  find (memberId) {
    return axios.get(`members/${memberId}`)
  },
  findProfile (memberId, profileId) {
    return axios.get(`members/${memberId}/profiles/${profileId}`)
  },
  saveProfile (memberId, data) {
    return axios.post(`members/${memberId}/profiles`, data)
  },
  saveAddress (memberId, profileId, data) {
    return axios.post(`members/${memberId}/profiles/${profileId}/address`, data)
  },
  makeGiftSetRequest (memberId, profileId, data) {
    return axios.post(`members/${memberId}/profiles/${profileId}/gift-sets`, data)
  },
  updateOrCreateChild (memberId, profileId, data, childId = null) {
    const method = childId ? 'put' : 'post'
    const url = childId ? `members/${memberId}/profiles/${profileId}/children/${childId}` : `members/${memberId}/profiles/${profileId}/children`

    return axios({ method, url, data })
  },
  deleteChild (memberId, profileId, childId) {
    return axios.delete(`members/${memberId}/profiles/${profileId}/children/${childId}`)
  },
  fetchTrackings (memberId, profileId) {
    return axios.get(`members/${memberId}/profiles/${profileId}/tracking`)
  },
  fetchTracking (memberId, profileId, trackingId) {
    return axios.get(`members/${memberId}/profiles/${profileId}/tracking/${trackingId}`)
  },
  fetchTotal (params = {}) {
    return axios.get('members/total', {
      params: helpers.removePropertyIsEmpty(params),
    })
  },
}

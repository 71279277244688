/*=========================================================================================
  File Name: moduleDashboardMutations.js
  Description: Dashboard Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

const stageChildernSchema = (_vm, children) => {
  return children.map((child) => {
    const schema = schemas.dashboardChildren()

    // child
    Object.assign(schema, _vm.lodash.pick(child, ['id', 'name']))

    // profile
    Object.assign(
      schema.profile,
      _vm.lodash.pick(_vm.lodash.get(child, 'profile.data'), Object.keys(schema.profile)),
    )

    // current stage
    schema.current_stage = _vm.lodash.get(child, 'outbound_stage.data.current.data.title')

    // member id
    schema.member_id = _vm.lodash.get(child, 'profile.data.member.data.id')
    // profile id
    schema.profile_id = _vm.lodash.get(child, 'profile.data.id')

    return schema
  })
}

export default {
  SET_STAGES (state, stages) {
    Object.assign(state, { stages })
  },
  SET_PRODUCT_LINKS (state, productLinks) {
    Object.assign(state, { productLinks })
  },
  SET_CLUB (state, { club, data: clubs }) {
    if (!Object.prototype.hasOwnProperty.call(state.outbounds, club)) {
      Vue.set(state.outbounds, club, {})
    }

    const stages = []
    clubs.forEach((stage) => {
      stages.push({
        id: stage.id,
        title: stage.title,
        total: this._vm.lodash.get(stage, 'children.meta.pagination.total', 0),
      })

      const children = Object.assign(
        schemas.listing(),
        this._vm.lodash.pick(this._vm.lodash.get(stage, 'children'), ['data', 'meta']),
      )

      Object.assign(children.data, stageChildernSchema(this._vm, children.data))

      Vue.set(state.outbounds[club], stage.id, children)
    })

    Object.assign(state, { stages })
  },
  SET_OUTBOUNDS (state, { club, stage, data: outbounds }) {
    if (!Object.prototype.hasOwnProperty.call(state.outbounds, club)) {
      Vue.set(state.outbounds, club, {})
    }

    const children = Object.assign(
      schemas.listing(),
      this._vm.lodash.pick(outbounds, ['data', 'meta']),
    )

    Object.assign(children.data, stageChildernSchema(this._vm, children.data))

    Vue.set(state.outbounds[club], stage, children)
  },
  RESET_CLUB (state) {
    Object.keys(state.outbounds)
      .forEach((club) => {
        Vue.set(state.outbounds, club, {})
      })
  },
}

/*=========================================================================================
  File Name: moduleReportActions.js
  Description: Report Module Actions
==========================================================================================*/

import $report from '@/http/requests/report'

export default {
  async generate ({ commit }, params = {}) {
    const { data } = await $report.generate(params)

    commit('SET_EXPORT', { data })
  },

  async download ({ commit }, { uuid } = {}) {
    commit('RESET')

    return await $report.download(uuid)
  },

  reset ({ commit }) {
    commit('RESET')
  },
}

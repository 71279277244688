import Vue from 'vue'
import VueLodash from 'vue-lodash'

// Array
import concat from 'lodash/concat'
import findIndex from 'lodash/findIndex'
import first from 'lodash/first'
import intersection from 'lodash/intersection'
import last from 'lodash/last'

// Collection
import some from 'lodash/some'

// Object
import get from 'lodash/get'
import has from 'lodash/has'
// import keys from 'lodash/keys'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import set from 'lodash/set'
import unset from 'lodash/unset'
// import values from 'lodash/values'

// Lang
import castArray from 'lodash/castArray'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isDate from 'lodash/isDate'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'
import isUndefined from 'lodash/isUndefined'
import toArray from 'lodash/toArray'
import toInteger from 'lodash/toInteger'

// String
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'

Vue.use(VueLodash, {
  lodash: {
    // Array
    concat,
    findIndex,
    first,
    intersection,
    last,

    // Collection
    some,

    // Object
    get,
    has,
    omit,
    omitBy,
    pick,
    pickBy,
    set,
    unset,

    // Lang
    castArray,
    isArray,
    isBoolean,
    isDate,
    isEmpty,
    isNaN,
    isNil,
    isNull,
    isObject,
    isUndefined,
    toArray,
    toInteger,

    // String
    camelCase,
    capitalize,
    lowerCase,
    kebabCase,
    startCase,
  },
})

/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
==========================================================================================*/

const getters = {
  windowBreakPoint: (state) => {
    if (state.windowWidth >= 1200) {
      return 'xl'
    } else if (state.windowWidth >= 992) {
      return 'lg'
    } else if (state.windowWidth >= 768) {
      return 'md'
    } else if (state.windowWidth >= 576) {
      return 'sm'
    }

    return 'xs'
  },
  scrollbarTag: ({ isTouchDevice }) => (isTouchDevice ? 'div' : 'VuePerfectScrollbar'),
}

export default getters

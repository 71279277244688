/*=========================================================================================
  File Name: moduleTrackingState.js
  Description: Tracking Module State
==========================================================================================*/

import schemas from '@/utils/schemas'

export default {
  topics: [],
  products: [],
  failReasons: [],
  tracking: schemas.tracking(),
  lists: schemas.listing(),
  info: null,
}

/*=========================================================================================
  File Name: moduleUserState.js
  Description: User Module State
==========================================================================================*/

import schemas from '@/utils/schemas'

export default {
  lists: schemas.listing(),
  info: schemas.user(),
  tokens: [],
  token: null,
}

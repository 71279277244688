/*=========================================================================================
  File Name: moduleMemberActions.js
  Description: Member Module Actions
==========================================================================================*/

import $member from '@/http/requests/member'

export default {
  async fetch ({ commit }, params = {}) {
    const { data } = await $member.fetch(params)

    commit('SET_MEMBERS', { club: params.club, data })
  },

  async create ({}, { data }) {
    const attributes = this._vm.lodash.pick(data, [
      'profile',
      'address',
      'children',
      'gift_set',
    ])

    const {
      data: { data: member },
    } = await $member.create(attributes)

    return member
  },
  async find ({ commit }, { id, profileId = null } = {}) {
    const {
      data: { data: member },
    } = await $member.find(id)
    commit('SET_MEMBER', member)

    if (profileId) {
      const profile = member.profiles.data.find(({ id }) => id === profileId)

      Object.assign(profile, {
        contact_channel: this._vm.lodash.get(profile, 'contact_channel') || [],
      })

      const [firstChild, secondChild] = this._vm.lodash.get(
        profile,
        'children.data',
        [],
      )
      const [childNext] = this._vm.lodash.get(profile, 'children_next.data', [])

      commit('SET_PROFILE', profile)
      commit('SET_ADDRESS', this._vm.lodash.get(profile, 'address.data'))
      commit('SET_FIRST_CHILD', firstChild)
      commit('SET_SECOND_CHILD', secondChild)
      commit('SET_GIFT_SETS', this._vm.lodash.get(profile, 'gift_sets.data'))
      commit('SET_CHILD_NEXT', childNext)
    }
  },
  async findProfile ({ commit }, { id, profileId }) {
    const {
      data: { data: profile },
    } = await $member.findProfile(id, profileId)

    Object.assign(profile, {
      contact_channel: this._vm.lodash.get(profile, 'contact_channel') || [],
    })

    commit('SET_MEMBER', this._vm.lodash.get(profile, 'member.data', null))

    const [firstChild, secondChild] = this._vm.lodash.get(
      profile,
      'children.data',
      [],
    )
    const [childNext] = this._vm.lodash.get(profile, 'children_next.data', [])

    commit('SET_PROFILE', profile)
    commit('SET_ADDRESS', this._vm.lodash.get(profile, 'address.data'))
    commit('SET_FIRST_CHILD', firstChild)
    commit('SET_SECOND_CHILD', secondChild)
    commit('SET_GIFT_SETS', this._vm.lodash.get(profile, 'gift_sets.data'))
    commit('SET_CHILD_NEXT', childNext)
  },
  async saveProfile ({ commit }, { id, profile }) {
    const {
      data: { data },
    } = await $member.saveProfile(id, profile)
    commit('SET_PROFILE', data)

    return data
  },
  async saveAddress ({ commit }, { id, profileId, address }) {
    const {
      data: { data },
    } = await $member.saveAddress(id, profileId, address)
    commit('SET_ADDRESS', data)

    return data
  },
  async makeGiftSetRequest ({ commit }, { id, profileId, giftSet }) {
    const {
      data: { data },
    } = await $member.makeGiftSetRequest(id, profileId, giftSet)
    commit('SET_GIFT_SETS', data)

    return data
  },
  async updateOrCreateChild (
    { commit, state },
    { id, profileId, childId = null, child } = {},
  ) {
    const {
      data: { data },
    } = await $member.updateOrCreateChild(id, profileId, child, childId)

    const { firstChild, secondChild } = state

    if (!firstChild || parseInt(firstChild?.id, 10) === parseInt(data.id, 10)) {
      commit('SET_FIRST_CHILD', data)
    } else if (
      !secondChild ||
      secondChild.id === null ||
      parseInt(secondChild?.id, 10) === parseInt(data.id, 10)
    ) {
      commit('SET_SECOND_CHILD', data)
    }

    return data
  },
  async deleteChild ({ commit, state }, { id, profileId, childId }) {
    await $member.deleteChild(id, profileId, childId)

    const { firstChild, secondChild } = state

    if (parseInt(firstChild?.id, 10) === parseInt(childId, 10)) {
      commit('RESET_FIRST_CHILD')
    } else if (parseInt(secondChild?.id, 10) === parseInt(childId, 10)) {
      commit('RESET_SECOND_CHILD')
    }

    return true
  },
  async fetchTrackings ({ commit }, { id, profileId }) {
    const {
      data: { data },
    } = await $member.fetchTrackings(id, profileId)

    commit('SET_TRACKINGS', data)
  },
  async fetchTracking ({ commit }, { id, profileId, trackingId }) {
    const {
      data: { data },
    } = await $member.fetchTracking(id, profileId, trackingId)

    commit('SET_TRACKING', data)
  },
  async fetchTotal ({ commit }, params = {}) {
    const { data } = await $member.fetchTotal(params)

    commit('SET_TOTAL', { club: params.club, data })
  },

  async quickviewProfile ({ commit }, { memberId, profileId }) {
    const {
      data: { data: profile },
    } = await $member.findProfile(memberId, profileId)

    commit('SET_PROFILE_QUICKVIEW', {
      profile,
      children: this._vm.lodash.get(profile, 'children.data[0]', []),
      address: this._vm.lodash.get(profile, 'address.data'),
      gift_sets: this._vm.lodash.get(profile, 'gift_sets.data'),
    })
  },
  resetMembers ({ commit }, { club }) {
    commit('SET_MEMBERS', { club, data: {} })
  },
  reset ({ commit }) {
    commit('RESET_CLUB')
    commit('RESET_MEMBER')
    commit('RESET_PROFILE')
    commit('RESET_ADDRESS')
    commit('RESET_FIRST_CHILD')
    commit('RESET_SECOND_CHILD')
    commit('RESET_TRACKINGS')
    commit('RESET_GIFT_SETS')
    commit('RESET_CHILD_NEXT')
  },
}

/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
==========================================================================================*/

import auth from '@/auth/authService'
// import firebase from 'firebase/app'
// import 'firebase/auth'

export default {
  isUserLoggedIn: () => {
    return sessionStorage.getItem('userInfo') && auth.isAuthenticated()
  },
}

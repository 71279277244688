import axios from '@/axios'
import helpers from '@/utils/helpers'

export default {
  fetch (params = {}) {
    return axios.get('gift-sets-requests', {
      params: helpers.removePropertyIsEmpty(params),
    })
  },
  create (data) {
    return axios.post('gift-sets-requests', data)
  },
  find (id) {
    return axios.get(`gift-sets-requests/${id}`)
  },
  update (id, data) {
    return axios.put(`gift-sets-requests/${id}`, data)
  },
  bulksStatus (status, ids) {
    return axios.put(`gift-sets-requests/bulks/${status}`, { ids })
  },
  generate (params = {}) {
    return axios.post('gift-sets-requests/exports', helpers.removePropertyIsEmpty(params))
  },
  download (uuid) {
    return axios.get(`gift-sets-requests/exports/${uuid}`, {
      responseType: 'blob', // blob, arraybuffer, stream
    })
    // console.log({ data: helpers.removePropertyIsEmpty(data) })
    // return axios({
    //   url: 'gift-sets-requests/exports',
    //   method: 'get',
    //   responseType: 'stream', // blob, arraybuffer, stream
    //   params: data
    // })

    // return axios.get('gift-sets-requests/exports', {
    //   params: helpers.removePropertyIsEmpty(data),
    //   responseType: 'blob' // blob, arraybuffer, stream
    // })
  },
  delete (id) {
    return axios.delete(`gift-sets-requests/${id}`)
  },
}

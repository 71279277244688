/*=========================================================================================
  File Name: moduleDataState.js
  Description: Data Module State
==========================================================================================*/

export default {
  outboundStages: [],
  trackingTopics: [],
  trackingProducts: [],
  trackingReasons: [],
  trackingStores: [],
  cities: [],
  products: [],
  stores: [],
  roles: [],
  giftSets: [],
}

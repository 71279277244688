import axios from '@/axios'

export default {
  fetchTopic () {
    return axios.get('data/tracking-topics')
  },
  fetchProduct () {
    return axios.get('data/tracking-products')
  },
  fetchFailReason () {
    return axios.get('data/tracking-reasons')
  },
  updateOrCreate (memberId, profileId, data, trackingId = null) {
    const method = trackingId ? 'put' : 'post'
    const url = trackingId ? `members/${memberId}/profiles/${profileId}/tracking/${trackingId}` : `members/${memberId}/profiles/${profileId}/tracking`

    return axios({ method, url, data })
  },
}

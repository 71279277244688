/*=========================================================================================
  File Name: moduleDashboardState.js
  Description: Dashboard Module State
==========================================================================================*/

export default {
  stages: [],
  productLinks: {
    links: [],
    nodes: [],
    depths: [],
    levels: [],
  },
  outbounds: {},
}

/*=========================================================================================
  File Name: moduleTrackingGetters.js
  Description: Tracking Module Getters
==========================================================================================*/

export default {
  lists: ({ lists }) => lists,
  info: ({ info }) => info,
  tracking: ({ tracking }) => tracking,
  topics: ({ topics }) => topics,
  products: ({ products }) => products,
  failReasons: ({ failReasons }) => failReasons,
}

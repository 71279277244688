/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
==========================================================================================*/

import $user from '@/http/requests/user'

export default {
  async fetch ({ commit }, { page = 1, search = null } = {}) {
    const { data: users } = await $user.fetch({ page, search })
    commit('SET_LIST', users)
  },
  async find ({ commit }, { id }) {
    const {
      data: { data: user },
    } = await $user.find(id)
    commit('SET_INFO', user)
  },
  async create ({ commit }, { data }) {
    const attributes = this._vm.lodash.pick(data, [
      'email',
      'password',
      'password_confirmation',
      'name',
      'avatar',
      'active',
      'roles',
    ])

    const {
      data: { data: user },
    } = await $user.create(attributes)
    commit('SET_INFO', user)

    return user
  },
  async update ({ commit, rootState }, { id, data }) {
    const attributes = this._vm.lodash.pick(data, [
      'email',
      'password',
      'password_confirmation',
      'name',
      'avatar',
      'active',
      'roles',
    ])

    const {
      data: { data: user },
    } = await $user.update(id, attributes)
    commit('SET_INFO', user)

    // Update in AppActiveUser
    if (this._vm.lodash.get(rootState, 'AppActiveUser.uid', 0) === user.id) {
      commit(
        'UPDATE_USER_INFO',
        {
          displayName: user.name,
          email: user.email,
          photoURL: user.avatar,
        },
        { root: true },
      )
    }

    return user
  },
  async delete ({ id }) {
    await $user.delete(id)

    return true
  },
  async uploadAvatar ({ commit, rootState }, { id, params }) {
    const {
      data: { data: user },
    } = await $user.uploadAvatar(id, params)
    commit('SET_AVATAR', user.avatar)

    // Update in AppActiveUser
    if (this._vm.lodash.get(rootState, 'AppActiveUser.uid', 0) === user.id) {
      commit(
        'UPDATE_USER_INFO',
        {
          photoURL: user.avatar,
        },
        { root: true },
      )
    }

    return user
  },
  async deleteAvatar ({ commit, rootState }, { id }) {
    const {
      data: { data: user },
    } = await $user.deleteAvatar(id)
    commit('SET_AVATAR', user.avatar)

    // Update in AppActiveUser
    if (this._vm.lodash.get(rootState, 'AppActiveUser.uid', 0) === user.id) {
      commit(
        'UPDATE_USER_INFO',
        {
          photoURL: user.avatar,
        },
        { root: true },
      )
    }

    return user
  },
  async fetchAccessTokens ({ commit }, { id }) {
    const { data } = await $user.fetchAccessTokens(id)
    commit('SET_TOKENS', data.data)
  },
  async generateAccessToken ({ commit }, { id, data }) {
    const token = await $user.generateAccessToken(id, data)
    commit('SET_TOKEN', token.data)

    return token.data
    //commit('RESET_TOKENS')
  },
  async revokeAccessToken ({ commit }, { id, tokenId }) {
    await $user.revokeAccessToken(id, tokenId)

    commit('RESET_TOKENS')
  },
  reset ({ commit }) {
    commit('RESET_LIST')
    commit('RESET_INFO')
  },
}

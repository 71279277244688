/*=========================================================================================
  File Name: moduleDataActions.js
  Description: Data Module Actions
==========================================================================================*/

import $data from '@/http/requests/data'

export default {
  async fetchOutboundStages ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchOutboundStages()

    commit('SET_OUTBOUND_STAGES', data)
  },
  async fetchTrackingTopics ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchTrackingTopics()

    commit('SET_TRACKING_TOPICS', data)
  },
  async fetchTrackingProducts ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchTrackingProducts()

    commit('SET_TRACKING_PRODUCTS', data)
  },
  async fetchTrackingReasons ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchTrackingReasons()

    commit('SET_TRACKING_REASONS', data)
  },
  async fetchTrackingStores ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchTrackingStores()

    commit('SET_TRACKING_STORES', data)
  },
  async fetchCities ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchCities()

    commit('SET_CITIES', data)
  },
  async fetchProducts ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchProducts()

    commit('SET_PRODUCTS', data)
  },
  async fetchStores ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchStores()

    commit('SET_STORES', data)
  },
  async fetchRoles ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchRoles()

    commit('SET_ROLES', data)
  },
  async fetchGiftSets ({ commit }) {
    const {
      data: { data },
    } = await $data.fetchGiftSets()

    commit('SET_GIFT_SETS', data)
  },
}

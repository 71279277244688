/*=========================================================================================
  File Name: moduleMemberState.js
  Description: Member Module State
==========================================================================================*/

import schemas from '@/utils/schemas'
import constants from '@/utils/constants'

const clubs = constants.memberClubs.map((club) => {
  club.members = schemas.listing()
  return club
})

export default {
  clubs,
  member: null,
  profile: null,
  firstChild: null,
  secondChild: null,
  childNext: null,
  giftSets: [],
  trackings: [],
  tracking: null,
  profileQuickView: null,
  total: [],
}

/*=========================================================================================
  File Name: schemas.js
  Description: Schemas
==========================================================================================*/

export default {
  listing: () => ({
    data: [],
    meta: {
      pagination: {},
    },
  }),

  // dashboard
  dashboardChildren: () => ({
    id: null,
    profile_id: null,
    member_id: null,
    name: null,
    current_stage: null,
    profile: {
      id: null,
      name: null,
      email: null,
      phone_number: null,
      contact_channel: null,
    },
    created_at: null,
    updated_at: null,
  }),

  // Member
  members: () => ({
    id: null,
    email: null,
    phone_number: null,
    profile: null,
    created_at: null,
  }),
  member: () => ({
    id: null,
    name: null,
    email: null,
    phone_number: null,
    birthdate: null,
    contact_channel: null,
    club: null,
    platform: null,
    created_at: null,
    updated_at: null,
  }),
  memberProfile: () => ({
    id: null,
    club: null,
    platform: null,
    name: null,
    birthdate: null,
    phone_number: null,
    email: null,
    contact_channel: [],
    is_customer_converted: false,
    customer_converted_at: null,
    phone_format: null,
  }),
  memberChildren: () => ({
    id: null,
    pregnant: false,
    estimated_due_date: null,
    gender: null,
    name: null,
    birthdate: null,
    delivered: null,
    outbound_stage: null,
    product: null,
    store: null,
    is_master: false,
    delete: false,
    has_product: false,
  }),
  memberChildrenNext: () => ({
    child_id: null,
    member_id: null,
    member_profile_id: null,
  }),
  memberAddress: () => ({
    id: null,
    house: null,
    street: null,
    ward: null,
    district: null,
    city: null,
    zip_code: null,
  }),
  memberGiftSets: () => ({
    id: null,
    slug: null,
    name: null,
    status: null,
    remark: null,
    created_at: null,
  }),
  memberGiftSetsActivity: () => ({
    id: null,
    status: null,
    title: null,
    message: null,
    created_by: null,
    created_at: null,
  }),
  memberTotal: () => ({
    total: null,
    total_customer: null,
  }),

  // Gift Sets Request
  giftSetsRequest: () => ({
    id: null,
    remark: null,
    tracking_code: null,
    status: null,
    gift_set: {
      name: null,
    },
    member: {
      id: null,
      club: null,
      platform: null,
      name: null,
      email: null,
      profile_id: null,
      member_id: null,
    },
    member_id: null,
    profile_id: null,
    created_at: null,
  }),

  // User
  users: () => ({
    id: null,
    email: null,
    name: null,
    avatar: null,
    active: false,
    roles: [],
    created_at: null,
    updated_at: null,
  }),
  user: () => ({
    id: null,
    email: null,
    password: null,
    password_confirmation: null,
    name: null,
    avatar: null,
    active: false,
    roles: [],
    created_at: null,
    updated_at: null,
  }),
  // role
  role: () => ({
    id: null,
    name: null,
    created_at: null,
  }),
  //user token
  token: () => ({
    id: null,
    name: null,
    token: null,
    created_at: null,
    updated_at: null,
    expired_at: null,
  }),
  tokens: () => ({
    id: null,
    name: null,
    created_at: null,
    updated_at: null,
    expired_at: null,
  }),

  // tracking
  trackings: () => ({
    id: null,
    tracking_number: null,
    type: null,
    tracking_topic: null,
    tracking_products: null,
    tracking_store: null,
    notes: null,
    status: null,
    tracking_reason: null,
    customer_converted: false,
    follow_up_at: null,
    created_at: null,
    created_by: null,
  }),
  tracking: () => ({
    id: null,
    tracking_number: null,
    type: null,
    tracking_topic_id: null,
    tracking_product: null,
    outbound_stage_id: null,
    tracking_store: null,
    notes: null,
    status: null,
    tracking_reason_id: null,
    customer_converted: false,
    follow_up_at: null,
    created_at: null,
    created_by: null,
  }),

  // tracking topic
  trackingTopics: () => ({
    id: null,
    title: null,
    types: [],
    parent: null,
    is_active: false,
    created_at: null,
    updated_at: null,
  }),
  trackingTopic: () => ({
    id: null,
    title: null,
    types: [],
    parent_id: 0,
    is_active: true,
    created_at: null,
    updated_at: null,
  }),

  // tracking reason
  trackingReasons: () => ({
    id: null,
    title: null,
    types: [],
    is_active: true,
    created_at: null,
    updated_at: null,
  }),
  trackingReason: () => ({
    id: null,
    title: null,
    types: [],
    is_active: true,
    created_at: null,
    updated_at: null,
  }),

  // store
  trackingStores: () => ({
    id: null,
    title: null,
    slug: null,
    is_active: false,
    created_at: null,
    updated_at: null,
  }),
  trackingStore: () => ({
    id: null,
    title: null,
    slug: null,
    is_active: true,
    created_at: null,
    updated_at: null,
  }),

  sampleSets: () => ({
    id: null,
    slug: null,
    name: null,
    is_lock: null,
    is_active: null,
    created_at: null,
  }),
  sampleSet: () => ({
    id: null,
    slug: null,
    name: null,
    is_lock: null,
    is_active: null,
    created_at: null,
    updated_at: null,
  }),
  contactUs: () => ({
    id: null,
    uuid: null,
    firstname: null,
    lastname: null,
    phone_number: null,
    email: null,
    message: null,
    status: null,
    remark: null,
    created_at: null,
    updated_at: null,
    member_profile_id: null,
    member_id: null,
  }),

  // me
  me: () => ({
    email: null,
    password: null,
    password_confirmation: null,
    name: null,
    avatar: null,
    created_at: null,
    updated_at: null,
  }),
}

/*=========================================================================================
  File Name: moduleUserMutations.js
  Description: User Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

export default {
  SET_LIST (state, giftSets) {
    const lists = schemas.listing()

    Object.assign(lists.meta, this._vm.lodash.get(giftSets, 'meta'))

    Object.assign(
      lists.data,
      this._vm.lodash.get(giftSets, 'data', []).map((giftSet) => {
        const _schema = schemas.giftSetsRequest()

        Object.assign(
          _schema,
          this._vm.lodash.pick(giftSet, Object.keys(_schema)),
        )

        // gift set
        _schema.gift_set = this._vm.lodash.pick(
          this._vm.lodash.get(giftSet, 'gift_set.data'),
          Object.keys(schemas.giftSetsRequest().gift_set),
        )

        // member
        _schema.member = this._vm.lodash.pick(
          this._vm.lodash.get(giftSet, 'profile.data'),
          Object.keys(schemas.giftSetsRequest().member),
        )

        // member_id, profile_id
        Object.assign(_schema, {
          profile_id: this._vm.lodash.get(giftSet, 'profile.data.id'),
          member_id: this._vm.lodash.get(
            giftSet,
            'profile.data.member.data.id',
          ),
        })

        return _schema
      }),
    )
    // console.log({ mutations: 'SET_LIST', lists })

    Vue.set(state, 'lists', lists)
  },
  SET_EXPORT (state, { data }) {
    Vue.set(state, 'uuid', this._vm.lodash.get(data, 'uuid'))
  },
  RESET_LIST (state) {
    Vue.set(state, 'lists', schemas.listing())
  },
  RESET_EXPORT (state) {
    Object.assign(state, {
      uuid: null,
    })
  },
}

/*=========================================================================================
  File Name: store.js
  Description: Vuex store
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import auth from './auth'
import me from './me'
import member from './member'
import tracking from './tracking'
import data from './data'
import user from './user'
import trackingTopic from './tracking-topic'
import trackingReason from './tracking-reason'
import giftSetsRequest from './gift-sets-request'
import report from './report'
import sampleSet from './sample-set'
import contactUs from './contact-us'
import dashboard from './dashboard'

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth,
    me,
    member,
    tracking,
    data,
    user,
    trackingTopic,
    trackingReason,
    giftSetsRequest,
    report,
    sampleSet,
    contactUs,
    dashboard,
  },
})

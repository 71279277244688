import axios from '@/axios'

export default {
  fetchOutboundStages () {
    return axios.get('data/outbound-stages')
  },
  fetchTrackingTopics () {
    return axios.get('data/tracking-topics')
  },
  fetchTrackingProducts () {
    return axios.get('data/tracking-products')
  },
  fetchTrackingReasons () {
    return axios.get('data/tracking-reasons')
  },
  fetchTrackingStores () {
    return axios.get('data/tracking-stores')
  },
  fetchProducts () {
    return axios.get('data/products')
  },
  fetchStores () {
    return axios.get('data/stores')
  },
  fetchCities () {
    return axios.get('data/cities')
  },
  fetchRoles () {
    return axios.get('data/roles')
  },
  fetchGiftSets () {
    return axios.get('data/gift-sets')
  },
}

import axios from '@/axios'
// import helpers from '@/utils/helpers'

export default {
  fetch () {
    return axios.get('me')
  },
  update (data) {
    return axios.put('me', data)
  },
  uploadAvatar (data) {
    return axios.put('me/avatar', data)
  },
  deleteAvatar () {
    return axios.delete('me/avatar')
  },
}

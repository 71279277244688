import axios from '@/axios'
import helpers from '@/utils/helpers'

export default {
  fetch ({ page = 1, parent_id = 0, search = null} = {}) {
    return axios.get('tracking-topics', {
      params: helpers.removePropertyIsEmpty({
        parent_id,
        search,
        page,
      }),
    })
  },
  create (data) {
    return axios.post('tracking-topics', data)
  },
  find (id) {
    return axios.get(`tracking-topics/${id}`)
  },
  update (id, data) {
    return axios.put(`tracking-topics/${id}`, data)
  },
  delete (id) {
    return axios.delete(`tracking-topics/${id}`)
  },
}

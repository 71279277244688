import axios from '@/axios'
import helpers from '@/utils/helpers'

export default {
  fetch ({ page = 1, search = null} = {}) {
    return axios.get('users', {
      params: helpers.removePropertyIsEmpty({
        search,
        page,
      }),
    })
  },
  create (data) {
    return axios.post('users', data)
  },
  find (id) {
    return axios.get(`users/${id}`)
  },
  update (id, data) {
    return axios.put(`users/${id}`, data)
  },
  delete (id) {
    return axios.delete(`users/${id}`)
  },
  uploadAvatar (id, data) {
    return axios.put(`users/${id}/avatar`, data)
  },
  deleteAvatar (id) {
    return axios.delete(`users/${id}/avatar`)
  },
  fetchAccessTokens (id) {
    return axios.get(`users/${id}/token`)
  },
  generateAccessToken (id, data) {
    return axios.post(`users/${id}/token`, data)
  },
  revokeAccessToken (id, tokenId) {
    return axios.delete(`users/${id}/token/${tokenId}`)
  },
}

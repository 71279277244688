/*=========================================================================================
  File Name: moduleSampleSetActions.js
  Description: Sample Set Module Actions
==========================================================================================*/

import $sampleSet from '@/http/requests/sample-set'

export default {
  async fetch ({ commit }, { page = 1, search = null } = {}) {
    const { data: sampleSets } = await $sampleSet.fetch({ page, search })
    commit('SET_LIST', sampleSets)
  },
  async find ({ commit }, { id }) {
    const {
      data: { data: sampleSet },
    } = await $sampleSet.find(id)
    commit('SET_INFO', sampleSet)
  },
  async create ({ commit }, { data }) {
    const attributes = this._vm.lodash.pick(data, ['name', 'is_active'])

    const {
      data: { data: sampleSet },
    } = await $sampleSet.create(attributes)
    commit('SET_INFO', sampleSet)

    return sampleSet
  },
  async update ({ commit }, { id, data }) {
    const attributes = this._vm.lodash.pick(data, ['name', 'is_active'])

    const {
      data: { data: sampleSet },
    } = await $sampleSet.update(id, attributes)
    commit('SET_INFO', sampleSet)

    return sampleSet
  },
  async delete ({ commit }, { id }) {
    await $sampleSet.delete(id)

    commit('RESET_LIST')
    return true
  },
  reset ({ commit }) {
    commit('RESET_LIST')
    commit('RESET_INFO')
  },
}

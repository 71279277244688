/*=========================================================================================
  File Name: moduleTrackingTopicState.js
  Description: Tracking Topic Module State
==========================================================================================*/

import schemas from '@/utils/schemas'

export default {
  lists: schemas.listing(),
  info: schemas.trackingTopic(),
}

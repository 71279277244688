/*=========================================================================================
  File Name: moduleUserMutations.js
  Description: User Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

export default {
  SET_LIST (state, users) {
    const lists = schemas.listing()

    Object.assign(lists.meta, this._vm.lodash.get(users, 'meta'))

    Object.assign(
      lists.data,
      this._vm.lodash.get(users, 'data', []).map((user) => {
        const _schema = schemas.users()

        Object.assign(_schema, this._vm.lodash.pick(user, Object.keys(_schema)))

        // _schema.roles = this._vm.lodash.get(user, 'roles.data', []).map((role) => (role.name))

        return _schema
      }),
    )

    Vue.set(state, 'lists', lists)
  },
  SET_INFO (state, user) {
    const info = schemas.user()

    Object.assign(info, this._vm.lodash.pick(user, Object.keys(info)))

    // _schema.roles = this._vm.lodash.get(user, 'roles.data', []).map((role) => (role.name))
    info.roles = this._vm.lodash.first(this._vm.lodash.get(user, 'roles', []))

    Vue.set(state, 'info', info)
  },
  SET_AVATAR (state, avatar) {
    const info = state.info

    Object.assign(info, {
      avatar,
    })

    Vue.set(state, 'info', info)
  },
  SET_TOKENS (state, tokens) {
    Vue.set(state, 'tokens', tokens)
  },
  SET_TOKEN (state, token) {
    Vue.set(state, 'token', token)
  },
  RESET_LIST (state) {
    Vue.set(state, 'lists', schemas.listing())
  },
  RESET_INFO (state) {
    Vue.set(state, 'info', schemas.user())
  },
  RESET_TOKENS (state) {
    Vue.set(state, 'tokens', [])
  },
}

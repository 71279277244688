/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL || '/',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    {
      path: '',
      // name: 'layoutMain',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/OutboundDashboard.vue'),
          meta: {
            type: 'dashboard',
            pageTitle: 'Outbound Dashboard',
            rule: 'all',
          },
        },
        // {
        //   path: '/outbound',
        //   name: 'outbound',
        //   component: () => import('./views/Dashboard.vue')
        // },
        {
          path: '/outbound/:memberId/profiles/:profileId',
          name: 'outbound.edit',
          component: () => import('./views/member/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Dashboard', url: '/' },
              { title: 'Member', url: '/members' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Members',
            parent: 'home',
            rule: 'agent',
            type: 'dashboard',
          },
        },

        /*
         * member
         */
        {
          path: '/members',
          name: 'member',
          component: () => import('./views/member/List.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Members', url: '/members' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Members',
            rule: 'agent',
          },
        },
        {
          path: '/members/:memberId/profiles/:profileId',
          name: 'member.profile.edit',
          component: () => import('./views/member/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Member', url: '/members' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Member',
            rule: 'agent',
            parent: 'member',
          },
        },
        {
          path: '/members/create',
          name: 'member.profile.create',
          component: () => import('./views/member/Create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Member', url: '/members' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Member',
            rule: 'agent',
            parent: 'member',
          },
        },

        /*
         * gift sets
         */
        {
          path: '/gift-sets-requests',
          name: 'gift-sets-request',
          component: () => import('./views/gift-sets-request/List.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Sets Requests', url: '/gift-sets-requests' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Gift Sets Requests',
            rule: 'agent',
          },
        },

        /*
         * reports
         */
        {
          path: '/reports',
          name: 'report',
          component: () => import('./views/report/Main.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Reports', url: '/reports', active: true },
            ],
            pageTitle: 'Reports',
            rule: 'admin',
          },
        },

        /*
         * users
         */
        {
          path: '/users',
          name: 'user',
          component: () => import('./views/user/List.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users', url: '/users' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Users',
            rule: 'admin',
          },
        },
        {
          path: '/users/new',
          name: 'user.new',
          component: () => import('./views/user/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users', url: '/users' },
              { title: 'Add New', active: true },
            ],
            pageTitle: 'Users',
            parent: 'user',
            rule: 'admin',
          },
        },
        {
          path: '/users/:id',
          name: 'user.edit',
          component: () => import('./views/user/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users', url: '/users' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Users',
            parent: 'user',
            rule: 'admin',
          },
        },

        /*
         * tracking topics
         */
        {
          path: '/tracking-topics',
          name: 'tracking-topic',
          component: () => import('./views/tracking-topic/List.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tracking Topics', url: '/tracking-topics' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Tracking Topics',
            rule: 'admin',
          },
        },
        {
          path: '/tracking-topics/new',
          name: 'tracking-topic.new',
          component: () => import('./views/tracking-topic/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tracking Topics', url: '/tracking-topics' },
              { title: 'New', active: true },
            ],
            pageTitle: 'Tracking Topics',
            parent: 'tracking-topic',
            rule: 'agent',
          },
        },
        {
          path: '/tracking-topics/:id',
          name: 'tracking-topic.edit',
          component: () => import('./views/tracking-topic/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tracking Topics', url: '/tracking-topics' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Tracking Topics',
            parent: 'tracking-topic',
            rule: 'agent',
          },
        },

        /*
         * tracking reasons
         */
        {
          path: '/tracking-reasons',
          name: 'tracking-reason',
          component: () => import('./views/tracking-reason/List.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tracking Reasons', url: '/tracking-reasons' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Tracking Reasons',
            rule: 'admin',
          },
        },
        {
          path: '/tracking-reasons/new',
          name: 'tracking-reason.new',
          component: () => import('./views/tracking-reason/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tracking Reasons', url: '/tracking-reasons' },
              { title: 'New', active: true },
            ],
            pageTitle: 'Tracking Reasons',
            parent: 'tracking-reason',
            rule: 'agent',
          },
        },
        {
          path: '/tracking-reasons/:id',
          name: 'tracking-reason.edit',
          component: () => import('./views/tracking-reason/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tracking Reasons', url: '/tracking-reasons' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Tracking Reasons',
            parent: 'tracking-reason',
            rule: 'agent',
          },
        },

        /*
         * sample sets
         */
        {
          path: '/sample-sets',
          name: 'sample-set',
          component: () => import('./views/sample-set/List.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sample Sets', url: '/sample-sets' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Sample Sets',
            rule: 'admin',
          },
        },
        {
          path: '/sample-sets/new',
          name: 'sample-set.new',
          component: () => import('./views/sample-set/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sample Sets', url: '/sample-sets' },
              { title: 'New', active: true },
            ],
            pageTitle: 'Sample Sets',
            parent: 'sample-set',
            rule: 'agent',
          },
        },
        {
          path: '/sample-sets/:id',
          name: 'sample-set.edit',
          component: () => import('./views/sample-set/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Sample Sets', url: '/sample-sets' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Sample Sets',
            parent: 'sample-set',
            rule: 'agent',
          },
        },

        /*
         * contact us
         */
        {
          path: '/contact-us',
          name: 'contact-us',
          component: () => import('./views/contact-us/List.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Contact Us', url: '/contact-us' },
              { title: 'List', active: true },
            ],
            pageTitle: 'Contact Us',
            rule: 'admin',
          },
        },
        {
          path: '/contact-us/new',
          name: 'contact-us.new',
          component: () => import('./views/contact-us/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Contact Us', url: '/contact-us' },
              { title: 'New', active: true },
            ],
            pageTitle: 'Contact Us',
            parent: 'contact-us',
            rule: 'agent',
          },
        },
        {
          path: '/contact-us/:id',
          name: 'contact-us.edit',
          component: () => import('./views/contact-us/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Contact Us', url: '/contact-us' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'Contact Us',
            parent: 'contact-us',
            rule: 'agent',
          },
        },

        /*
         * profile
         */
        {
          path: '/profile',
          name: 'profile',
          component: () => import('./views/profile/Edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Profile', active: true },
            ],
            pageTitle: 'Profile',
            rule: 'all',
          },
        },
      ],
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      // name: 'layoutFullPage',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/auth/login',
          name: 'auth-login',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            rule: 'all',
            guest: true,
          },
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'all',
          },
        },
        // {
        //   path: '/auth/logout',
        //   name: 'auth-login',
        //   component: () => import('@/views/pages/Logout.vue'),
        //   meta: {
        //     guest: true
        //   }
        // },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            public: true,
          },
        },
      ],
    },

    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
      meta: {
        public: true,
      },
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.public)) {
    return next()
  }

  const isUserLoggedIn = store.state.auth.isUserLoggedIn()

  if (to.matched.some((record) => record.meta.guest)) {
    if (!isUserLoggedIn) {
      return next()
    }

    return next({ name: 'home' })
  }

  if (isUserLoggedIn) {
    return next()
  }

  return next({
    name: 'auth-login',
    query: {
      to: to.fullPath,
    },
  })
})

export default router

/*=========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
==========================================================================================*/

import $giftSetsRequest from '@/http/requests/gift-sets-request'

export default {
  async fetch ({ commit }, params = {}) {
    const { data: lists } = await $giftSetsRequest.fetch(params)
    commit('SET_LIST', lists)
  },

  async update ({ commit }, { id, data }) {
    const attributes = this._vm.lodash.pick(data, [
      'email',
      'password',
      'password_confirmation',
      'name',
      'avatar',
      'active',
      'roles',
    ])

    const {
      data: { data: info },
    } = await $giftSetsRequest.update(id, attributes)
    commit('SET_INFO', info)

    return info
  },

  async bulksStatus ({}, { status, ids }) {
    await $giftSetsRequest.bulksStatus(status, ids)

    return true
  },

  async generate ({ commit }, params = {}) {
    const { data } = await $giftSetsRequest.generate(params)

    commit('SET_EXPORT', { data })
  },
  async download ({ commit }, { uuid } = {}) {
    commit('RESET_EXPORT')

    return await $giftSetsRequest.download(uuid)
  },

  /* eslint-disable no-empty-pattern */
  /* eslint-enable no-empty-pattern */

  resetExport ({ commit }) {
    commit('RESET_EXPORT')
  },
  reset ({ commit }) {
    commit('RESET_LIST')
    commit('RESET_EXPORT')
  },
}

/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
==========================================================================================*/

import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
// import { colors, themeConfig } from '@/../themeConfig' // Theme Configurations
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
// Vue.use(Vuesax, {
//   theme: {
//     colors
//   },
//   rtl: themeConfig.rtl
// })
Vue.use(Vuesax)

// lodash
import './lodash'

// Laravel Echo
// import './laravelEcho'

// helpers
import helpers from './utils/helpers'
Vue.prototype.$helpers = helpers

// axios
import axios from './axios'
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'

// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)

import jwt from '@/http/requests/auth/jwt'
// created hook
jwt.init()

// ACL
import acl from './acl/acl'

// Globally Registered Components
import './globalComponents'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store'

// VeeValidate (v2.x)
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// moment
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

// Default TimeZone
moment.tz.setDefault(
  Vue._.get(process.env, 'VUE_APP_TIMEZONE', 'Asia/Phnom_Penh'),
)

Vue.use(VueMoment, {
  moment,
})

// SweetAlert
import VueSweetalert2 from 'vue-sweetalert2'
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
import '@/assets/css/iconfont.css'

Vue.config.productionTip = false

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  acl,
  render: (h) => h(App),
}).$mount('#app')

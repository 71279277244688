/*=========================================================================================
  File Name: moduleSampleSetMutations.js
  Description: Sample Set Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

export default {
  SET_LIST (state, sampleSets) {
    const lists = schemas.listing()

    Object.assign(lists.meta, this._vm.lodash.get(sampleSets, 'meta'))

    Object.assign(
      lists.data,
      this._vm.lodash.get(sampleSets, 'data', []).map((sampleSet) => {
        const _schema = schemas.sampleSets()

        Object.assign(
          _schema,
          this._vm.lodash.pick(sampleSet, Object.keys(_schema)),
        )
        return _schema
      }),
    )

    Vue.set(state, 'lists', lists)
  },
  SET_INFO (state, sampleSet) {
    const _schema = schemas.sampleSet()

    Object.assign(
      _schema,
      this._vm.lodash.pick(sampleSet, Object.keys(_schema)),
    )

    Vue.set(state, 'info', _schema)
  },

  RESET_LIST (state) {
    Vue.set(state, 'lists', schemas.listing())
  },
  RESET_INFO (state) {
    Vue.set(state, 'info', schemas.sampleSet())
  },
}

/*=========================================================================================
  File Name: moduleTrackingTopicActions.js
  Description: Tracking Topic Module Actions
==========================================================================================*/

import $trackingTopic from '@/http/requests/tracking-topic'

export default {
  async fetch ({ commit }, { page = 1, parent_id = 0, search = null } = {}) {
    const { data: trackingTopics } = await $trackingTopic.fetch({
      page,
      parent_id,
      search,
    })
    commit('SET_LIST', trackingTopics)
  },
  async find ({ commit }, { id }) {
    const {
      data: { data: trackingTopic },
    } = await $trackingTopic.find(id)
    commit('SET_INFO', trackingTopic)
  },
  async create ({ commit }, { data }) {
    const attributes = this._vm.lodash.pick(data, [
      'title',
      'parent_id',
      'types',
      'is_active',
    ])

    const {
      data: { data: trackingTopic },
    } = await $trackingTopic.create(attributes)
    commit('SET_INFO', trackingTopic)

    return trackingTopic
  },
  async update ({ commit }, { id, data }) {
    const attributes = this._vm.lodash.pick(data, [
      'title',
      'parent_id',
      'types',
      'is_active',
    ])

    const {
      data: { data: trackingTopic },
    } = await $trackingTopic.update(id, attributes)
    commit('SET_INFO', trackingTopic)

    return trackingTopic
  },
  async delete ({ commit }, { id }) {
    await $trackingTopic.delete(id)

    commit('RESET_LIST')
    return true
  },
  reset ({ commit }) {
    commit('RESET_LIST')
    commit('RESET_INFO')
  },
}

/*=========================================================================================
  File Name: moduleDashboardGetters.js
  Description: Dashboard Module Getters
==========================================================================================*/

export default {
  stages: ({ stages }) => stages,
  productLinks: ({ productLinks }) => productLinks,
  outbounds: ({ outbounds }) => outbounds,
}

/*=========================================================================================
  File Name: moduleMemberGetters.js
  Description: Member Module Getters
==========================================================================================*/

export default {
  clubs: ({ clubs }) => clubs,
  member: ({ member }) => member,
  profile: ({ profile }) => profile,
  address: ({ address }) => address,
  firstChild: ({ firstChild }) => firstChild,
  secondChild: ({ secondChild }) => secondChild,
  masterChild: ({ firstChild, secondChild }) => [firstChild, secondChild].find(({ is_master }) => is_master),
  childNext: ({ childNext }) => childNext,
  trackings: ({ trackings }) => trackings,
  tracking: ({ tracking }) => tracking,
  giftSets: ({ giftSets }) => giftSets,
  profileQuickView: ({ profileQuickView }) => profileQuickView,
  total: ({ total }) => total,
}

import axios from '@/axios'
import helpers from '@/utils/helpers'

export default {
  generate (params = {}) {
    return axios.post('reports/generate', helpers.removePropertyIsEmpty(params))
  },
  download (uuid) {
    return axios.get(`reports/download/${uuid}`, {
      responseType: 'blob', // blob, arraybuffer, stream
    })
  },
}

import axios from '@/axios'
import helpers from '@/utils/helpers'

export default {
  fetch ({ page = 1, search = null} = {}) {
    return axios.get('contact-us', {
      params: helpers.removePropertyIsEmpty({
        search,
        page,
      }),
    })
  },
  create (data) {
    return axios.post('contact-us', data)
  },
  find (id) {
    return axios.get(`contact-us/${id}`)
  },
  update (id, data) {
    return axios.put(`contact-us/${id}`, data)
  },
  delete (id) {
    return axios.delete(`contact-us/${id}`)
  },
}

/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
==========================================================================================*/

import jwt from '@/http/requests/auth/jwt'
import auth from '@/auth/authService'
import router from '@/router'

export default {
  // JWT
  async loginJWT ({ commit }, payload) {
    // const payloadAuth = {}

    try {
      const { data } = await jwt.login(
        payload.userDetails.email,
        payload.userDetails.password,
      )

      if (!data.access_token) {
        return Promise.reject({ message: 'Wrong Email or Password' })
      }

      Object.assign({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: data.expires_in,
        remember: payload.remember || false,
      })

      jwt.setTokens({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: data.expires_in,
        remember: false,
      })

      auth.login({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: data.expires_in,
        remember: false,
      })

      commit('SET_BEARER', data.access_token)
    } catch (error) {
      return Promise.reject(error?.response?.data || error)
    }

    try {
      const response = await jwt.profile()

      if (!this._vm.lodash.has(response, 'data.data')) {
        return Promise.reject({ message: 'Wrong Email or Password' })
      }

      const user = this._vm.lodash.get(response, 'data.data')

      // console.log({ response, user })
      auth.setProfile({
        uid: user.id,
        email: this._vm.lodash.get(user, 'email'),
        displayName: this._vm.lodash.get(user, 'name'),
        photoURL: this._vm.lodash.get(user, 'avatar'),
        userRole: this._vm.lodash.get(user, 'role', '').toLowerCase(),
      })

      // payloadAuth.profile = {
      //   uid: user.id,
      //   email: user.email,
      //   displayName: user.name,
      //   photoURL: user.avatar,
      //   roles: user.roles
      // }
      // console.log({ payloadAuth })

      auth.login()
      router.push(router.currentRoute.query.to || '/')

      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  logoutJWT ({ commit }) {
    jwt.logout()

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    commit('REMOVE_USER_INFO', null, { root: true })

    return Promise.resolve()
  },

  loadAccessToken ({ commit }) {
    const accessToken = jwt.getAccessToken()

    if (accessToken) {
      commit('SET_BEARER', accessToken)
    }
  },

  fetchAccessToken () {
    return jwt.refreshToken()
  },
}

/*=========================================================================================
  File Name: moduleContactUsActions.js
  Description: Contact Us Module Actions
==========================================================================================*/

import $contactUs from '@/http/requests/contact-us'

export default {
  async fetch ({ commit }, { page = 1, search = null } = {}) {
    const { data: contactUs } = await $contactUs.fetch({ page, search })
    commit('SET_LIST', contactUs)
  },
  async find ({ commit }, { id }) {
    const {
      data: { data: contactUs },
    } = await $contactUs.find(id)
    commit('SET_INFO', contactUs)
  },
  async create ({ commit }, { data }) {
    const attributes = this._vm.lodash.pick(data, ['remark'])

    const {
      data: { data: contactUs },
    } = await $contactUs.create(attributes)
    commit('SET_INFO', contactUs)

    return contactUs
  },
  async update ({ commit }, { id, data }) {
    const attributes = this._vm.lodash.pick(data, ['remark'])

    const {
      data: { data: contactUs },
    } = await $contactUs.update(id, attributes)
    commit('SET_INFO', contactUs)

    return contactUs
  },
  async delete ({ commit }, { id }) {
    await $contactUs.delete(id)

    commit('RESET_LIST')
    return true
  },
  reset ({ commit }) {
    commit('RESET_LIST')
    commit('RESET_INFO')
  },
}

/*=========================================================================================
  File Name: moduleTrackingReasonState.js
  Description: Tracking Reason Module State
==========================================================================================*/

import schemas from '@/utils/schemas'

export default {
  lists: schemas.listing(),
  info: schemas.sampleSet(),
}

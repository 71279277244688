/*=========================================================================================
  File Name: moduleDataGetters.js
  Description: Data Module Getters
==========================================================================================*/

export default {
  outboundStages: ({ outboundStages }) => outboundStages,
  trackingTopics: ({ trackingTopics }) => trackingTopics,
  trackingProducts: ({ trackingProducts }) => trackingProducts,
  trackingReasons: ({ trackingReasons }) => trackingReasons,
  trackingStores: ({ trackingStores }) => trackingStores,
  cities: ({ cities }) => cities,
  products: ({ products }) => products,
  stores: ({ stores }) => stores,
  roles: ({ roles }) => roles,
  giftSets: ({ giftSets }) => giftSets,
}

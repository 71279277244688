/*=========================================================================================
  File Name: moduleTrackingReasonActions.js
  Description: Tracking Reason Module Actions
==========================================================================================*/

import $trackingReason from '@/http/requests/tracking-reason'

export default {
  async fetch ({ commit }, { page = 1, parent_id = 0, search = null } = {}) {
    const { data: trackingReasons } = await $trackingReason.fetch({
      page,
      parent_id,
      search,
    })
    commit('SET_LIST', trackingReasons)
  },
  async find ({ commit }, { id }) {
    const {
      data: { data: trackingReason },
    } = await $trackingReason.find(id)
    commit('SET_INFO', trackingReason)
  },
  async create ({ commit }, { data }) {
    const attributes = this._vm.lodash.pick(data, [
      'title',
      'parent_id',
      'types',
      'is_active',
    ])

    const {
      data: { data: trackingReason },
    } = await $trackingReason.create(attributes)
    commit('SET_INFO', trackingReason)

    return trackingReason
  },
  async update ({ commit }, { id, data }) {
    const attributes = this._vm.lodash.pick(data, [
      'title',
      'parent_id',
      'types',
      'is_active',
    ])

    const {
      data: { data: trackingReason },
    } = await $trackingReason.update(id, attributes)
    commit('SET_INFO', trackingReason)

    return trackingReason
  },
  async delete ({ commit }, { id }) {
    await $trackingReason.delete(id)

    commit('RESET_LIST')
    return true
  },
  reset ({ commit }) {
    commit('RESET_LIST')
    commit('RESET_INFO')
  },
}

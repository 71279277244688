/*=========================================================================================
  File Name: moduleUserGetters.js
  Description: User Module Getters
==========================================================================================*/

export default {
  lists: ({ lists }) => lists,
  info: ({ info }) => info,
  token: ({ token }) => token,
  tokens: ({ tokens }) => tokens,
}

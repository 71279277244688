/*=========================================================================================
  File Name: moduleTrackingReasonMutations.js
  Description: Tracking Reason Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

export default {
  SET_LIST (state, trackingReasons) {
    const lists = schemas.listing()

    Object.assign(lists.meta, this._vm.lodash.get(trackingReasons, 'meta'))

    Object.assign(
      lists.data,
      this._vm.lodash.get(trackingReasons, 'data', []).map((trackingReason) => {
        const _schema = schemas.trackingReasons()

        Object.assign(
          _schema,
          this._vm.lodash.pick(trackingReason, Object.keys(_schema)),
        )

        // parent
        _schema.parent = this._vm.lodash.get(
          trackingReason,
          'parent.data.title',
        )

        // _schema.roles = this._vm.lodash.get(user, 'roles.data', []).map((role) => (role.name))

        return _schema
      }),
    )

    Vue.set(state, 'lists', lists)
  },
  SET_INFO (state, trackingReason) {
    const _schema = schemas.trackingReason()

    Object.assign(
      _schema,
      this._vm.lodash.pick(trackingReason, Object.keys(_schema)),
    )

    _schema.roles = this._vm.lodash.first(
      this._vm.lodash.get(trackingReason, 'roles', []),
    )

    Vue.set(state, 'info', _schema)
  },

  RESET_LIST (state) {
    Vue.set(state, 'lists', schemas.listing())
  },
  RESET_INFO (state) {
    Vue.set(state, 'info', schemas.trackingReason())
  },
}

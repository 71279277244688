/*=========================================================================================
  File Name: moduleTrackingActions.js
  Description: Tracking Module Actions
==========================================================================================*/

import $tracking from '@/http/requests/tracking'

export default {
  async fetchTopic ({ commit }) {
    const {
      data: { data },
    } = await $tracking.fetchTopic()

    commit('SET_TOPICS', data)
  },
  async fetchProduct ({ commit }) {
    const {
      data: { data },
    } = await $tracking.fetchProduct()

    commit('SET_PRODUCTS', data)
  },
  async fetchFailReason ({ commit }) {
    const {
      data: { data },
    } = await $tracking.fetchFailReason()

    commit('SET_FAIL_REASONS', data)
  },
  async updateOrCreate (
    context,
    { id, profileId, trackingId = null, tracking },
  ) {
    const {
      data: { data },
    } = await $tracking.updateOrCreate(id, profileId, tracking, trackingId)

    return data
  },
}

import axios from '@/axios'
import store from '@/store'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  init () {
    axios.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    }, (error) => Promise.reject(error))

    axios.interceptors.response.use(
      response => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if ([401, 403].includes(response?.status) && store.state.auth.isUserLoggedIn()) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true
            store.dispatch('auth/fetchAccessToken')
              .then((access_token) => {
                isAlreadyFetchingAccessToken = false
                onAccessTokenFetched(access_token)
              })
          } else {
            store.dispatch('auth/logoutJWT')
          }

          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber(access_token => {
              originalRequest.headers.Authorization = `Bearer ${access_token}`
              resolve(axios(originalRequest))
            })
          })

          return retryOriginalRequest
        }

        return Promise.reject(error)
      })
  },
  login (email, password) {
    return axios.post('auth/login', {
      email,
      password,
    })
  },
  logout () {
    return axios.get('auth/logout')
  },
  profile () {
    return axios.get('me')
  },

  getAccessToken () {
    return sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
  },

  getRefreshToken () {
    return sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
  },

  // eslint-disable-next-line no-unused-vars
  setTokens ({ access_token, refresh_token, expires_in, remember = false} = {}) {
    const storage = remember ? localStorage : sessionStorage

    storage.setItem('accessToken', access_token)
    storage.setItem('refreshToken', refresh_token)
  },

  refreshToken () {
    return axios.post('auth/refresh-token', {
      // accessToken: this.getAccessToken(),
      refresh_token: this.getRefreshToken(),
    })
  },
}

/*=========================================================================================
  File Name: helpers.js
  Description: Helper functions
==========================================================================================*/

import Vue from 'vue'
import moment from 'moment'

const removePropertyIsEmpty = (obj) => (
  Object.entries(obj)
    .reduce((a, [k, v]) => (
      v === null || v === undefined || v === '' ? a : (Vue.lodash.set(a, k, v), a)
    ), {})

  // # https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
  // # Filter all falsy values ( "", 0, false, null, undefined )
  // Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  //
  // # Filter null and undefined values:
  // Object.entries(obj).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})
  //
  // # Filter ONLY null:
  // Object.entries(obj).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})
  //
  // # Filter ONLY undefined:
  // Object.entries(obj).reduce((a,[k,v]) => (v === undefined ? a : (a[k]=v, a)), {})
)

const removePropertyIsNull = (obj) => (
  Object.entries(obj)
    .reduce((a, [k, v]) => (
      v === null ? a : (Vue.lodash.set(a, k, v), a)
    ), {})
)

const removePropertyIsUndefined = (obj) => (
  Object.entries(obj)
    .reduce((a, [k, v]) => (
      v === undefined ? a : (Vue.lodash.set(a, k, v), a)
    ), {})
)

const dateFormat = (date, format = 'D MMM YY, HH:mm') => {
  if (Vue.lodash.isEmpty(date)) {
    return ''
  }

  const dt = moment(date)

  if (dt.isAfter(moment().subtract(1, 'hours'))) {
    return dt.fromNow()
  }

  if (format === 'humanize') {
    // return dt.duration().humanize(true)
    return dt.fromNow()
  }

  return dt.format(format)
}

export default {
  removePropertyIsEmpty,
  removePropertyIsNull,
  removePropertyIsUndefined,
  dateFormat,
}

/*=========================================================================================
  File Name: moduleDashboardActions.js
  Description: Dashboard Module Actions
==========================================================================================*/

import $dashboard from '@/http/requests/dashboard'

export default {
  async fetchStages ({ commit }) {
    const { data: { data } } = await $dashboard.fetchStages()

    commit('SET_STAGES', data)
  },
  async fetchProductLinks ({ commit }, { date_start, date_end }) {
    const { data } = await $dashboard.fetchProductLinks(date_start, date_end)

    commit('SET_PRODUCT_LINKS', data)

    return data
  },
  async fetchClub ({ commit }, { club }) {
    const { data: { data } } = await $dashboard.fetchClub(club)

    commit('SET_CLUB', { club, data })
  },
  async fetch ({ commit }, { club, stage, page }) {
    const { data } = await $dashboard.fetch(club, stage, page)

    commit('SET_OUTBOUNDS', { club, stage, data })
  },
  reset ({ commit }) {
    commit('RESET_CLUB')
  },
}

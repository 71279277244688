/*=========================================================================================
  File Name: moduleMemberMutations.js
  Description: Member Module Mutations
==========================================================================================*/

import Vue from 'vue'

import schemas from '@/utils/schemas'

const reduceMemberChildren = ({ _vm, child }) => {
  if (_vm.lodash.isObject(_vm.lodash.get(child, 'product.data'))) {
    _vm.lodash.set(child, 'product', _vm.lodash.get(child, 'product.data.slug'))
  }

  if (_vm.lodash.isObject(_vm.lodash.get(child, 'store.data'))) {
    _vm.lodash.set(child, 'store', _vm.lodash.get(child, 'store.data.slug'))
  }

  if (_vm.lodash.isObject(_vm.lodash.get(child, 'outbound_stage.data'))) {
    _vm.lodash.set(
      child,
      'outbound_stage',
      _vm.lodash.get(child, 'outbound_stage.data'),
    )

    if (
      _vm.lodash.isObject(_vm.lodash.get(child, 'outbound_stage.current.data'))
    ) {
      _vm.lodash.set(
        child,
        'outbound_stage.current',
        _vm.lodash.get(child, 'outbound_stage.current.data'),
      )
    }

    if (
      _vm.lodash.isObject(_vm.lodash.get(child, 'outbound_stage.next.data'))
    ) {
      _vm.lodash.set(
        child,
        'outbound_stage.next',
        _vm.lodash.get(child, 'outbound_stage.next.data'),
      )
    }
  }

  const _schema = schemas.memberChildren()

  Object.assign(_schema, _vm.lodash.pick(child, Object.keys(_schema)))

  _vm.lodash.set(
    _schema,
    'delete',
    _vm.lodash.isUndefined(_vm.lodash.get(child, 'id')),
  )

  // console.log({ member: 'mutations', _schema })

  return _schema
}

const reduceGiftSets = ({ _vm, giftSets }) => {
  Object.assign(giftSets, {
    current: _vm.lodash.get(giftSets, 'current.data', []).map((giftSet) => {
      const _schema = schemas.memberGiftSets()

      Object.assign(_schema, _vm.lodash.pick(giftSet, Object.keys(_schema)))

      // Gift Set
      if (_vm.lodash.isObject(_vm.lodash.get(giftSet, 'gift_set.data'))) {
        Object.assign(
          _schema,
          _vm.lodash.pick(_vm.lodash.get(giftSet, 'gift_set.data'), [
            'slug',
            'name',
          ]),
        )
      }

      return _schema
    }),
    history: _vm.lodash.get(giftSets, 'history.data', []).map((history) => {
      const _schema = schemas.memberGiftSetsActivity()

      Object.assign(_schema, _vm.lodash.pick(history, Object.keys(_schema)))

      // title
      _schema.title = _vm.lodash.capitalize(history.status)

      // message
      _schema.message = _vm.lodash.get(history, 'properties.message')

      // gift set
      Object.assign(_schema, {
        gift_set: _vm.lodash.pick(_vm.lodash.get(history, 'gift_set.data'), [
          'id',
          'slug',
        ]),
      })

      return _schema
    }),
  })
  return giftSets
}

export default {
  SET_MEMBERS ({ clubs }, { club, data }) {
    const setterClub = clubs.find(({ key }) => key === club)

    if (setterClub) {
      const members = {
        data: this._vm.lodash.get(data, 'data', []),
        meta: this._vm.lodash.get(data, 'meta', []),
      }

      Object.assign(
        members.data,
        members.data.map((member) => {
          const _schema = schemas.members()

          Object.assign(
            _schema,
            this._vm.lodash.pick(member, Object.keys(_schema)),
          )

          _schema.profile = this._vm.lodash
            .get(member, 'profiles.data', [])
            .find((p) => p.club === club)

          return _schema
        }),
      )

      Vue.set(setterClub, 'members', members)
    }
  },
  RESET_CLUB (state) {
    Vue.set(
      state,
      'clubs',
      state.clubs.map((club) => {
        this._vm.lodash.set(club, 'members', schemas.listing())
        return club
      }),
    )
  },
  SET_MEMBER (state, member) {
    const _schema = schemas.member()

    Object.assign(_schema, this._vm.lodash.pick(member, Object.keys(_schema)))

    Vue.set(state, 'member', _schema)
  },
  RESET_MEMBER (state) {
    Vue.set(state, 'member', null)
  },
  SET_PROFILE (state, profile) {
    const _schema = schemas.memberProfile()

    Object.assign(_schema, this._vm.lodash.pick(profile, Object.keys(_schema)))

    Vue.set(state, 'profile', _schema)
  },
  RESET_PROFILE (state) {
    Vue.set(state, 'profile', null)
  },

  SET_ADDRESS (state, address) {
    const _schema = schemas.memberAddress()

    Object.assign(_schema, this._vm.lodash.pick(address, Object.keys(_schema)))

    Vue.set(state, 'address', _schema)
  },
  RESET_ADDRESS (state) {
    Vue.set(state, 'address', null)
  },

  SET_FIRST_CHILD (state, child) {
    Vue.set(state, 'firstChild', reduceMemberChildren({ _vm: this._vm, child }))
  },
  RESET_FIRST_CHILD (state) {
    Vue.set(state, 'firstChild', null)
  },

  SET_SECOND_CHILD (state, child) {
    Vue.set(
      state,
      'secondChild',
      reduceMemberChildren({ _vm: this._vm, child }),
    )
  },
  RESET_SECOND_CHILD (state) {
    Vue.set(state, 'secondChild', null)
  },

  SET_CHILD_NEXT (state, child) {
    const _schema = schemas.memberChildrenNext()

    Object.assign(_schema, this._vm.lodash.pick(child, Object.keys(_schema)))

    Vue.set(state, 'childNext', _schema)
  },
  RESET_CHILD_NEXT (state) {
    Vue.set(state, 'childNext', null)
  },

  SET_TOTAL (state, total) {
    const _schema = schemas.memberTotal()

    Object.assign(_schema, this._vm.lodash.pick(total, Object.keys(_schema)))

    Vue.set(state, 'total', total.data)
  },

  SET_TRACKINGS (state, trackings) {
    // console.log({ mutations: 'SET_TRACKINGS', trackings })
    Object.assign(
      trackings,
      trackings.map((tracking) => {
        const _schema = schemas.trackings()

        Object.assign(
          _schema,
          this._vm.lodash.pick(tracking, Object.keys(_schema)),
        )

        if (
          this._vm.lodash.isObject(
            this._vm.lodash.get(tracking, 'activity_created.data'),
          )
        ) {
          this._vm.lodash.set(
            _schema,
            'created_by',
            this._vm.lodash.get(
              tracking,
              'activity_created.data.causer.data.name',
            ),
          )
        }

        // Tracking Topic
        if (
          this._vm.lodash.isObject(
            this._vm.lodash.get(tracking, 'tracking_topic.data'),
          )
        ) {
          this._vm.lodash.set(
            _schema,
            'tracking_topic',
            this._vm.lodash.pick(
              this._vm.lodash.get(tracking, 'tracking_topic.data'),
              ['id', 'title'],
            ),
          )
        }

        // Tracking Product
        if (
          this._vm.lodash.isObject(
            this._vm.lodash.get(tracking, 'tracking_product.data'),
          )
        ) {
          this._vm.lodash.set(
            _schema,
            'tracking_product',
            this._vm.lodash.pick(
              this._vm.lodash.get(tracking, 'tracking_product.data'),
              ['id', 'title'],
            ),
          )
        }

        // Tracking Store
        if (
          this._vm.lodash.isObject(
            this._vm.lodash.get(tracking, 'tracking_store.data'),
          )
        ) {
          this._vm.lodash.set(
            _schema,
            'tracking_store',
            this._vm.lodash.pick(
              this._vm.lodash.get(tracking, 'tracking_store.data'),
              ['id', 'title'],
            ),
          )
        }

        // Tracking Reason
        if (
          this._vm.lodash.isObject(
            this._vm.lodash.get(tracking, 'tracking_reason.data'),
          )
        ) {
          this._vm.lodash.set(
            _schema,
            'tracking_reason',
            this._vm.lodash.pick(
              this._vm.lodash.get(tracking, 'tracking_reason.data'),
              ['id', 'title'],
            ),
          )
        }

        return _schema
      }),
    )

    // console.log({ mutations: 'SET_TRACKINGS', trackings })
    Vue.set(state, 'trackings', trackings)
  },

  RESET_TRACKINGS (state) {
    Vue.set(state, 'trackings', [])
  },

  SET_TRACKING (state, tracking) {
    // console.log({ mutations: 'SET_TRACKING', tracking })

    const _schema = schemas.tracking()

    Object.assign(_schema, this._vm.lodash.pick(tracking, Object.keys(_schema)))

    // Outbound Stage
    if (
      this._vm.lodash.isObject(
        this._vm.lodash.get(tracking, 'outbound_stage.data'),
      )
    ) {
      this._vm.lodash.set(
        _schema,
        'outbound_stage_id',
        this._vm.lodash.get(tracking, 'outbound_stage.data.id'),
      )
    }

    // Tracking Topic
    if (
      this._vm.lodash.isObject(
        this._vm.lodash.get(tracking, 'tracking_topic.data'),
      )
    ) {
      this._vm.lodash.set(
        _schema,
        'tracking_topic_id',
        this._vm.lodash.get(tracking, 'tracking_topic.data.id'),
      )
    }

    // Tracking Reason
    if (
      this._vm.lodash.isObject(
        this._vm.lodash.get(tracking, 'tracking_reason.data'),
      )
    ) {
      this._vm.lodash.set(
        _schema,
        'tracking_reason_id',
        this._vm.lodash.get(tracking, 'tracking_reason.data.id'),
      )
    }

    // Tracking Products
    if (
      this._vm.lodash.isArray(
        this._vm.lodash.get(tracking, 'tracking_products.data'),
      )
    ) {
      this._vm.lodash.set(
        _schema,
        'tracking_products',
        this._vm.lodash
          .get(tracking, 'tracking_products.data')
          .map((product) => product.slug),
      )
    }

    // Tracking Stores
    if (
      this._vm.lodash.isArray(
        this._vm.lodash.get(tracking, 'tracking_stores.data'),
      )
    ) {
      this._vm.lodash.set(
        _schema,
        'tracking_stores',
        this._vm.lodash
          .get(tracking, 'tracking_stores.data')
          .map((store) => store.slug),
      )
    }

    // console.log({ mutations: 'SET_TRACKING', _schema })
    Vue.set(state, 'tracking', _schema)
  },
  RESET_TRACKING (state) {
    Vue.set(state, 'tracking', null)
  },

  SET_GIFT_SETS (state, giftSets) {
    // console.log({ mutations: 'SET_GIFT_SETS', giftSets })

    Object.assign(giftSets, {
      current: this._vm.lodash
        .get(giftSets, 'current.data', [])
        .map((giftSet) => {
          const _schema = schemas.memberGiftSets()

          Object.assign(
            _schema,
            this._vm.lodash.pick(giftSet, Object.keys(_schema)),
          )

          // Gift Set
          if (
            this._vm.lodash.isObject(
              this._vm.lodash.get(giftSet, 'gift_set.data'),
            )
          ) {
            Object.assign(
              _schema,
              this._vm.lodash.pick(
                this._vm.lodash.get(giftSet, 'gift_set.data'),
                ['slug', 'name'],
              ),
            )
          }

          return _schema
        }),
      history: this._vm.lodash
        .get(giftSets, 'history.data', [])
        .map((history) => {
          const _schema = schemas.memberGiftSetsActivity()

          Object.assign(
            _schema,
            this._vm.lodash.pick(history, Object.keys(_schema)),
          )

          // title
          _schema.title = this._vm.lodash.capitalize(history.status)

          // message
          _schema.message = this._vm.lodash.get(history, 'properties.message')

          // gift set
          Object.assign(_schema, {
            gift_set: this._vm.lodash.pick(
              this._vm.lodash.get(history, 'gift_set.data'),
              ['id', 'slug'],
            ),
          })

          return _schema
        }),
    })
    // console.log({ mutations: 'state', giftSets})

    // Vue.set(state, 'giftSets', giftSets.map((giftSet) => giftSet.slug))
    Vue.set(state, 'giftSets', giftSets)
  },
  RESET_GIFT_SETS (state) {
    Vue.set(state, 'giftSets', [])
  },

  SET_PROFILE_QUICKVIEW (state, { profile, children, address, gift_sets }) {
    const _schemaProfile = schemas.memberProfile()

    Object.assign(
      _schemaProfile,
      this._vm.lodash.pick(profile, Object.keys(_schemaProfile)),
    )
    const _schemaAddress = schemas.memberAddress()

    Object.assign(
      _schemaAddress,
      this._vm.lodash.pick(address, Object.keys(_schemaAddress)),
    )

    Vue.set(state, 'profileQuickView', {
      profile: _schemaProfile,
      children: reduceMemberChildren({ _vm: this._vm, child: children }),
      address: _schemaAddress,
      gift_sets: reduceGiftSets({ _vm: this._vm, giftSets: gift_sets }),
    })
  },
}

import axios from '@/axios'
import helpers from '@/utils/helpers'

export default {
  fetch ({ page = 1, search = null} = {}) {
    return axios.get('gift-sets', {
      params: helpers.removePropertyIsEmpty({
        search,
        page,
      }),
    })
  },
  create (data) {
    return axios.post('gift-sets', data)
  },
  find (id) {
    return axios.get(`gift-sets/${id}`)
  },
  update (id, data) {
    return axios.put(`gift-sets/${id}`, data)
  },
  delete (id) {
    return axios.delete(`gift-sets/${id}`)
  },
}

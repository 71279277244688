/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
export const colors = {
  primary: '#17479e',
  success: '#28c76f',
  danger: '#ea5455',
  warning: '#ff9f43',
  dark: '#1e1e1e',
}

// CONFIGS
export const themeConfig = {
  disableCustomizer: true,
  disableThemeTour: true,
  footerType: 'static',
  hideScrollToTop: false,
  mainLayoutType: 'vertical',
  navbarColor: '#fff',
  navbarType: 'floating',
  routerTransition: 'zoom-fade',
  rtl: false,
  sidebarCollapsed: false,
  theme: 'light',
  userInfoLocalStorageKey: 'userInfo',
}

import Vue from 'vue'
import Vuesax from 'vuesax'
Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl })

export default themeConfig

/*=========================================================================================
  File Name: moduleReportMutations.js
  Description: Report Module Mutations
==========================================================================================*/

import Vue from 'vue'

export default {
  SET_EXPORT (state, { data }) {
    Vue.set(state, 'uuid', this._vm.lodash.get(data, 'uuid'))
  },

  RESET (state) {
    Object.assign(state, {
      uuid: null,
    })
    // Vue.set(state, 'uuid', null)
  },
}

/*=========================================================================================
  File Name: moduleTrackingMutations.js
  Description: Tracking Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

export default {
  SET_LIST (state, trackings) {
    const lists = schemas.listing()

    Object.assign(lists.meta, this._vm.lodash.get(trackings, 'meta'))

    Object.assign(
      lists.data,
      this._vm.lodash.get(trackings, 'data', []).map((tracking) => {
        const _schema = schemas.trackings()

        Object.assign(
          _schema,
          this._vm.lodash.pick(tracking, Object.keys(_schema)),
        )

        return _schema
      }),
    )

    Vue.set(state, 'lists', lists)
  },
  SET_INFO (state, tracking) {
    if (
      this._vm.lodash.isObject(
        this._vm.lodash.get(tracking, 'tracking_product.data'),
      )
    ) {
      this._vm.lodash.set(
        tracking,
        'tracking_product',
        this._vm.lodash.get(tracking, 'tracking_product.data.slug'),
      )
    }

    if (
      this._vm.lodash.isObject(
        this._vm.lodash.get(tracking, 'tracking_store.data'),
      )
    ) {
      this._vm.lodash.set(
        tracking,
        'tracking_store',
        this._vm.lodash.get(tracking, 'tracking_store.data.slug'),
      )
    }

    const _schema = schemas.tracking()

    Object.assign(_schema, this._vm.lodash.pick(tracking, Object.keys(_schema)))

    Vue.set(state, 'info', _schema)
  },

  RESET_LIST (state) {
    Vue.set(state, 'lists', schemas.listing())
  },
  RESET_INFO (state) {
    Vue.set(state, 'info', null)
  },

  SET_TRACKING (state, tracking) {
    Vue.set(state, 'tracking', tracking)
  },
  RESET_TRACKING (state) {
    Vue.set(state, 'tracking', null)
  },
  SET_TOPICS (state, topics) {
    Vue.set(state, 'topics', topics)
  },
  SET_PRODUCTS (state, products) {
    Vue.set(state, 'products', products)
  },
  SET_FAIL_REASONS (state, failReasons) {
    Vue.set(state, 'failReasons', failReasons)
  },
}

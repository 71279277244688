/*=========================================================================================
  File Name: moduleUserState.js
  Description: User Module State
==========================================================================================*/

import schemas from '@/utils/schemas'

export default {
  info: schemas.me(),
}

/*=========================================================================================
  File Name: constants.js
  Description: Constants variable
==========================================================================================*/

export default {
  memberClubs: [
    {
      key: 'blue',
      name: 'Blue Club',
      icon: 'icon-award',
      color: '#17479e',
      members: null,
    },
  ],
  giftSetsStatus: [
    {
      key: 'waiting',
      name: 'Waiting',
      icon: null,
      color: '',
    },
    {
      key: 'approved',
      name: 'Approved',
      icon: null,
      color: '',
    },
    {
      key: 'rejected',
      name: 'Rejected',
      icon: null,
      color: '',
    },
    {
      key: 'process',
      name: 'Process',
      icon: null,
      color: '',
    },
    {
      key: 'completed',
      name: 'Completed',
      icon: null,
      color: '',
    },
    {
      key: 'canceled',
      name: 'Canceled',
      icon: null,
      color: '',
    },
  ],
  giftSetsStatusOptions: [
    { label: 'Waiting', value: 'waiting' },
    { label: 'Approved', value: 'approved' },
    { label: 'Process', value: 'process' },
    { label: 'Completed', value: 'completed' },
    { label: 'Canceled', value: 'canceled' },
  ],

  memberSearchOptions: [
    { label: 'All', value: 1 },
    { label: 'Full Name', value: 2 },
    { label: 'Contact Number', value: 3 },
    { label: 'Status', value: 4 },
    { label: 'Contact Channel', value: 5 },
    { label: 'Action', value: 6 },
  ],
  statusOptions: [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ],
  typeOptions: [
    { label: 'Inbound', value: 'inbound' },
    { label: 'Outbound', value: 'outbound' },
  ],
  childrenDeliveredOptions: [
    { label: 'Natural', value: 'natural' },
    { label: 'C-Section', value: 'c-section' },
  ],

  reportOptions: [
    {
      label: 'Growth of Member',
      value: 'member-growth',
    },
    {
      label: 'Member Detail',
      value: 'member-detail',
    },
    {
      label: 'Staging Summary',
      value: 'staging-summary',
    },
    {
      label: 'Product Using Summary',
      value: 'product-using-summary',
    },
    {
      label: 'Performance of agents',
      value: 'agent-performance',
    },
  ],
}

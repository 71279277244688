import axios from '@/axios'

export default {
  fetchStages () {
    return axios.get('outbound-stages')
  },
  fetchProductLinks (date_start, date_end) {
    return axios.get('dashboard/product-links', {
      params: {
        date_start,
        date_end,
      },
    })
  },
  fetchClub (club) {
    return axios.get(`dashboard/${club}`)
  },
  fetch (club, stage, page = 1) {
    return axios.get(`dashboard/${club}/${stage}`, {
      params: {
        page,
      },
    })
  },
}

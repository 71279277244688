/*=========================================================================================
  File Name: moduleMeActions.js
  Description: Me Module Actions
==========================================================================================*/

import $me from '@/http/requests/me'

export default {
  async fetch ({ commit }) {
    const {
      data: { data: me },
    } = await $me.fetch()
    commit('SET_INFO', me)
  },
  async update ({ commit }, { data }) {
    const attributes = this._vm.lodash.pick(data, [
      'email',
      'password',
      'password_confirmation',
      'name',
      'avatar',
    ])

    const {
      data: { data: me },
    } = await $me.update(attributes)
    commit('SET_INFO', me)

    // Update in AppActiveUser
    commit(
      'UPDATE_USER_INFO',
      {
        displayName: me.name,
        email: me.email,
        photoURL: me.avatar,
      },
      { root: true },
    )

    return me
  },
  async uploadAvatar ({ commit }, { params }) {
    const {
      data: { data: me },
    } = await $me.uploadAvatar(params)
    commit('SET_AVATAR', me.avatar)

    // Update in AppActiveUser
    commit(
      'UPDATE_USER_INFO',
      {
        photoURL: me.avatar,
      },
      { root: true },
    )

    return me
  },
  async deleteAvatar ({ commit }) {
    const {
      data: { data: me },
    } = await $me.deleteAvatar()
    commit('SET_AVATAR', me.avatar)

    // Update in AppActiveUser
    commit(
      'UPDATE_USER_INFO',
      {
        photoURL: me.avatar,
      },
      { root: true },
    )

    return me
  },
}

/*=========================================================================================
  File Name: moduleTrackingTopicMutations.js
  Description: Tracking Topic Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

export default {
  SET_LIST (state, trackingTopics) {
    const lists = schemas.listing()

    Object.assign(lists.meta, this._vm.lodash.get(trackingTopics, 'meta'))

    Object.assign(
      lists.data,
      this._vm.lodash.get(trackingTopics, 'data', []).map((trackingTopic) => {
        const _schema = schemas.trackingTopics()

        Object.assign(
          _schema,
          this._vm.lodash.pick(trackingTopic, Object.keys(_schema)),
        )

        // parent
        _schema.parent = this._vm.lodash.get(trackingTopic, 'parent.data.title')

        // _schema.roles = this._vm.lodash.get(user, 'roles.data', []).map((role) => (role.name))

        return _schema
      }),
    )

    Vue.set(state, 'lists', lists)
  },
  SET_INFO (state, trackingTopic) {
    const _schema = schemas.trackingTopic()

    Object.assign(
      _schema,
      this._vm.lodash.pick(trackingTopic, Object.keys(_schema)),
    )

    _schema.roles = this._vm.lodash.first(
      this._vm.lodash.get(trackingTopic, 'roles', []),
    )

    Vue.set(state, 'info', _schema)
  },

  RESET_LIST (state) {
    Vue.set(state, 'lists', schemas.listing())
  },
  RESET_INFO (state) {
    Vue.set(state, 'info', schemas.trackingTopic())
  },
}

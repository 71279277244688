/*=========================================================================================
  File Name: moduleMeMutations.js
  Description: Me Module Mutations
==========================================================================================*/

import Vue from 'vue'
import schemas from '@/utils/schemas'

export default {
  SET_INFO (state, me) {
    const info = schemas.me()

    Object.assign(info, this._vm.lodash.pick(me, Object.keys(info)))

    Vue.set(state, 'info', info)
  },
  SET_AVATAR (state, avatar) {
    const info = state.info

    Object.assign(info, {
      avatar,
    })

    Vue.set(state, 'info', info)
  },
  RESET_INFO (state) {
    Vue.set(state, 'info', null)
  },
}

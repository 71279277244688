/*=========================================================================================
  File Name: moduleDataMutations.js
  Description: Data Module Mutations
==========================================================================================*/

export default {
  SET_OUTBOUND_STAGES (state, outboundStages) {
    Object.assign(state, { outboundStages })
  },
  SET_TRACKING_TOPICS (state, trackingTopics) {
    Object.assign(state, { trackingTopics })
  },
  SET_TRACKING_PRODUCTS (state, trackingProducts) {
    Object.assign(state, { trackingProducts })
  },
  SET_TRACKING_REASONS (state, trackingReasons) {
    Object.assign(state, { trackingReasons })
  },
  SET_TRACKING_STORES (state, trackingStores) {
    Object.assign(state, { trackingStores })
  },
  SET_CITIES (state, cities) {
    Object.assign(state, { cities })
  },
  SET_PRODUCTS (state, products) {
    Object.assign(state, { products })
  },
  SET_STORES (state, stores) {
    Object.assign(state, { stores })
  },
  SET_ROLES (state, roles) {
    Object.assign(state, { roles })
  },
  SET_GIFT_SETS (state, giftSets) {
    Object.assign(state, { giftSets })
  },
}
